import React, { Component } from 'react';
import { QueryForm } from './QueryForm';

export class CIDNLookup extends Component {
    displayName = "Search CIDN"; 
       
    constructor(props) {
        super(props);
        this.sourcePage = props.location;
    }

    render(a,b,c) {
        return (
            <div>
                <h1>Search by CIDN</h1>
                <p>Please enter in an CIDN (10 characters) in the form of: "<strong>7859690178</strong>"</p>
                <QueryForm source={this.sourcePage} location="CIDN" />
            </div>
        );
    }
}
