import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Glyphicon, Nav, Navbar, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './NavMenu.css';

export class NavMenu extends Component {
    displayName = NavMenu.name

  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    
    return (
      <Navbar inverse fixedTop fluid collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand>
            <Link to={'/'}>CIDNify</Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav>
            <LinkContainer to={{pathname:'/abn', search: this.props.props.location.search }} exact>
              <NavItem>
                <Glyphicon glyph='search' /> Search by ABN
              </NavItem>
            </LinkContainer>
            <LinkContainer to={{pathname: '/name', search: this.props.props.location.search}} exact>
              <NavItem>
                <Glyphicon glyph='search' /> Search by Name
              </NavItem>
            </LinkContainer>
            <LinkContainer to={{pathname: '/cidn', search: this.props.props.location.search}} exact>
              <NavItem>
                <Glyphicon glyph='search' /> Search by CIDN
              </NavItem>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
