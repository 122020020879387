import React, { Component } from 'react';

export class Home extends Component {
  displayName = Home.name

  render() {
    return (
      <div>
        <h1>Welcome to CIDNify</h1>
        <p>Your one stop shop for a Telstra CIDN</p>
        <p>For more information on the process CIDNs, visit our <a href='https://readify.sharepoint.com/sales-kit/Pages/Telstra%20CIDN.aspx'>Readdit page</a></p>
        <p>This application is powered by Telstra Purple</p>
      </div>
    );
  }
}
