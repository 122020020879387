import { Component } from 'react';
import { getToken } from '../adalConfig';

export class Fetcher extends Component {

    async FetchData(url) {
        let responseObj = {
            status: 0,
            data: null,
            type: 'json',
            isError: false,
            message: 'An error occured during the processing of your request.'
        };

        await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            }})
            .then(function (response) {

                responseObj.status = response.status;
                responseObj.isError = !response.ok;

                let contentType = response.headers.get('content-type');
                if (contentType.includes('application/json')) {
                    return response.json();
                }

                responseObj.type = 'text';
                responseObj.isError = true; //expected type is json
                return response.text();
            }).then(data => {
                responseObj.data = data;
                if (responseObj.isError) {
                    throw responseObj;
                }
                return responseObj.data;
            }).then(data => {
                responseObj.message = 'Records returned: ' + data.customers.length;
                responseObj.data = data;
                return responseObj;
            }).catch(responseObj => {
                if (responseObj.hasOwnProperty('data') && responseObj.data.hasOwnProperty('userMessage')) {
                    responseObj.message = responseObj.data.userMessage;
                }
            });
        return responseObj;
    }

    async PostData(url, data) {
        let responseObj = {
            status: 0,
            type: 'json',
            isError: false,
            data: 'An error occured during the processing of your request.'
        };
        await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            },
            body: JSON.stringify(data)
        }).then(function (response) {
            responseObj.status = response.status;
            responseObj.isError = !response.ok;

            let contentType = response.headers.get('content-type');
            if (contentType.includes('application/json')) {
                return response.json();
            }

            responseObj.type = 'text';
            responseObj.isError = true; //expected type is json
            return response.text();
        }).then(data => {
            responseObj.data = data;
            if (responseObj.isError) {
                throw responseObj;
            }
            return responseObj.data;
        }).catch(responseObj => {
            if (responseObj.hasOwnProperty('data') && responseObj.data.hasOwnProperty('userMessage')) {
                responseObj.message = responseObj.data.userMessage;
            }
            else if(typeof responseObj.hasOwnProperty('data') === typeof string){
                responseObj.message = responseObj.data;
            }
        });
    return responseObj;
    }
}