import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';


export default class App extends Component {
    displayName = App.name
    
    constructor(querystring) {
        super(querystring);
        this.querystring = querystring;
    }

    render() {
        return (
          <Route>
            <Route path='/' component={Layout}/>
          </Route>
        );
    }
}
