import { Component } from 'react';

export class Validation extends Component {

    static validateABN(value) {

        const searchStringLength = value.length;
        var search = '';

        let abnValid = value.match(/^\d{11}$/);

        if (abnValid || searchStringLength === 0) {
            search = '';
        } else if (searchStringLength >= 12) {
            search = 'Too many characters.';
        } else {
            search = 'Too few characters.';
        }
        return { search: search, valid: abnValid ? true : false};
    }

    static validateCIDN(value) {

        const searchStringLength = value.length;
        let cidnValid = /^\d+$/.test(value);
        var search = '';
        if (searchStringLength === 0) {
            search = '';
            cidnValid = false;
        } else if (cidnValid) {
            if (searchStringLength === 10) {
                search = '';
                cidnValid = true;
            } else if (searchStringLength > 10) {
                search = 'Too many characters.';
                cidnValid = false;
            } else {
                search = 'Too few characters.';
                cidnValid = false;
            }  
        } else {
            search = 'Please enter only digits (0-9)';
        }
        return { search: search, valid: cidnValid ? true : false};
    }

    static validateName(value) {
        const searchStringLength = value.length;
        var valid = searchStringLength >= 1;
        var search = '';

        if (searchStringLength === 0) {
            search = 'Must have at least a single character.';
        } 
        return { search: search, valid: valid };
    }
}