import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {Button} from 'react-bootstrap';

const { SearchBar } = Search;

export class FilterTable extends Component {


    static renderCustomerTable(customers) {
        const columns = [{
            dataField: 'cidn',
            text: 'CIDN',
            style: { fontWeight: 'bold' },
            headerStyle: {
                textOverflow: 'ellipsis',
                wordBreak: 'break-word',
                minWidth: '90px'
            }
        }, {
            dataField: 'ultimateParentCIDN',
            text: 'Ultimate CIDN',
            style: { fontWeight: 'bold' },
            headerStyle: {
                textOverflow: 'ellipsis',
                wordBreak: 'break-word',
                minWidth: '90px'
            }
        }, {
            dataField: 'companyName',
            text: 'Company Name',
            headerStyle: {
                textOverflow: 'ellipsis',
                wordBreak: 'break-word',
                minWidth: '90px'
            }
        }, {
            dataField: 'abn',
            text: 'ABN',
            headerStyle: {
                textOverflow: 'ellipsis',
                wordBreak: 'break-word',
                minWidth: '90px'
            }
        }, {
            dataField: 'costCentre',
            text: 'Cost Center',
            headerStyle: {
                textOverflow: 'ellipsis',
                wordBreak: 'break-word',
                minWidth: '90px'
            }
        }, {
            dataField: 'repName',
            text: 'Rep Name ',
            headerStyle: {
                textOverflow: 'ellipsis',
                wordBreak: 'break-word',
                minWidth: '90px'
            }
        }, {
            dataField: 'email',
            text: 'Email',
            headerStyle: {
                textOverflow: 'ellipsis',
                wordBreak: 'break-word',
                minWidth: '90px'
            }
        }, {
            dataField: 'phone',
            text: 'Phone',
            headerStyle: {
                textOverflow: 'ellipsis',
                wordBreak: 'break-word',
                minWidth: '90px'
            }
        }, {
            dataField: 'addr1',
            text: 'Address 1',
            headerStyle: {
                textOverflow: 'ellipsis',
                wordBreak: 'break-word',
                minWidth: '90px'
            }
        }, {
            dataField: 'addr2',
            text: 'Address 2',
            headerStyle: {
                textOverflow: 'ellipsis',
                wordBreak: 'break-word',
                minWidth: '90px'
            }
        }, {
            dataField: 'addr3',
            text: 'Address 3',
            headerStyle: {
                textOverflow: 'ellipsis',
                wordBreak: 'break-word',
                minWidth: '90px'
            }
        }, {
            dataField: 'locality',
            text: 'Locality',
            headerStyle: {
                textOverflow: 'ellipsis',
                wordBreak: 'break-word',
                minWidth: '90px'
            }
        }, {
            dataField: 'state',
            text: 'State',
            headerStyle: {
                textOverflow: 'ellipsis',
                wordBreak: 'break-word',
                minWidth: '90px'
            }
        }, {
            dataField: 'postalCode',
            text: 'Postal Code',
            headerStyle: {
                textOverflow: 'ellipsis',
                wordBreak: 'break-word',
                minWidth: '90px'
            }
        }, {
            dataField: 'lastUpdated',
            text: 'Last Updated',
            headerStyle: {
                textOverflow: 'ellipsis',
                wordBreak: 'break-word',
                minWidth: '90px'
            }
        }];
        const dividerStyle = {
            width: '5px',
            height: 'auto',
            display: 'inline-block',

        };

        return (
            <div>

                <ToolkitProvider
                    keyField="cidn"
                    data={customers}
                    columns={columns}
                    search
                >
                    {
                        props => (
                            <div>
                                <hr />
                                <SearchBar {...props.searchProps} style={{ width: '30%' }} />
                                <br />
                                <BootstrapTable
                                    {...props.baseProps} classes={'table'} rowStyle={{ minWidth: '60px' }} bordered={false}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>

        );

    }
}