import React, { Component } from 'react';
import { QueryForm } from './QueryForm';

export class NameLookup extends Component {
    displayName = NameLookup.name

    constructor(props) {
        super(props);
        this.sourcePage = props.location;
    }

    render(a, b, c) {
        return (
            <div>
                <h1>Search by Name</h1>
                <p>Please enter a company legal name, eg. "<strong>Readify Pty Ltd</strong>", or a wild card search, eg. "<strong>Readi*</strong>"</p>
                <QueryForm source={this.sourcePage} location="Name" />
            </div>
        );
    }
}
