import React, { Component } from 'react';
import { ABNSearchForm } from './ABNSearchForm';

export class ABNLookup extends Component {
    displayName = ABNLookup.name

    constructor(props) {
        super(props);
        this.sourcePage = props.location;
    }

    render(a,b,c) {
        return (
            <div>
                <h1>Search by ABN</h1>
                <p>Please enter in an ABN (11 characters) in the form of: "<strong>13097323781</strong>"</p>
                <ABNSearchForm source={this.sourcePage}/>
            </div>
        );
    }
}
