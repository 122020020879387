import { AuthenticationContext } from 'react-adal';

const tenantId = process.env.REACT_APP_AAD_TENANT_ID || 'fc6a7adc-53de-44ad-88fc-af783bbb1d6e';
const clientId = process.env.REACT_APP_AAD_CLIENT_ID || '5f5dee6f-f957-4b11-9acd-70a6ebc8635f';

const adalConfig = {
    tenant: tenantId,
    clientId: clientId,
    cacheLocation: 'localStorage'
}

export const authContext = new AuthenticationContext(adalConfig);
export const getToken = () => {
    return authContext.getCachedToken(authContext.config.clientId);
};
