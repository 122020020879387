import React, { Component } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { NavMenu } from './NavMenu';
import { Route } from 'react-router';
import { Home } from './Home';
import { ABNLookup } from './ABNLookup';
import { NameLookup } from './NameLookup';
import { CIDNLookup } from './CIDNLookup';

export class Layout extends Component {
    displayName = Layout.name

    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        return (
            <Grid fluid>
                <Row>
                    <Col xs={2}>
                        <NavMenu props={this.props} />
                    </Col >
                    <Col sm={10} lg={10}>
                        <Route exact path='/' component={Home} />
                        <Route exact path='/abn/' component={ABNLookup} />
                        <Route exact path='/abn?:values' component={ABNLookup} />
                        <Route exact path='/name/' component={NameLookup} />
                        <Route exact path='/name/:name?/:abn?/:cidn' component={NameLookup} />
                        <Route exact path='/cidn/:name?/:abn?/:cidn' component={CIDNLookup} />
                        <Route exact path='/cidn/' component={CIDNLookup} />
                    </Col>
                </Row>
            </Grid>
        );
    }
}
