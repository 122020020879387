import React, { Component } from 'react';
import { FilterTable } from './FilterTable';
import { SearchTool } from './SearchTool';
import { ToastContainer, toast } from 'react-toastify';
import { FormErrors } from './FormErrors';
import ReactLoading from 'react-loading';
import { Button, FormControl, Form, FormGroup } from 'react-bootstrap'
import { Fetcher } from './Fetcher';
import { Validation } from './Validation';

import Modal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
import '../index.css';
import { CIDNEntryForm } from './CIDNEntryForm';

const dividerStyle = {
    width: '5px',
    height: 'auto',
    display: 'inline-block'
};

Modal.setAppElement('#root');
export class QueryForm extends Component {
    displayName = "Query Form"

    constructor(props) {
        super(props);
        this.updateInput = this.updateInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            value: SearchTool.getSearchField(props),
            loading: false,
            customers: [],
            message: '',
            valid: false,
            response: '',
            formValid: props.source.search ? true : false,
            formErrors: { search: '', },
            caller: props.source,
            properties: props,
            fetcher: new Fetcher(),
            offset: 0,
            offsetBase: 0,
            source: props.location,
            searchedValue: '',
            modalIsOpen: false,
            isLastPage: false
        };
        this.handleNext = this.handleNext.bind(this);
        this.handlePrevious = this.handlePrevious.bind(this);
        this.handleQuery = this.handleQuery.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    updateInput(event) {
        const searchValue = event.target.value;
        this.setState({ value: searchValue },
            () => { this.validateField(searchValue); });

    }

    validateField(value) {
        var validated;
        if (this.state.caller.pathname === '/cidn') {
            validated = Validation.validateCIDN(value);
        } else {
            validated = Validation.validateName(value);
        }

        this.setState({
            formErrors: validated,
            valid: validated.valid
        }, this.validateForm);
    }

    validateForm() {
        this.setState({ formValid: this.state.valid });
    }

    async handleSubmit() {
        if (this.state.value && this.state.value !== "") {
            this.state.offset = 0;
            var offsetValue = await this.state.fetcher.FetchData('api/offset/');
            if (offsetValue.isError) {
                toast.error(offsetValue.message);
            }
            else {
                this.state.offsetBase = offsetValue.data;
            }
            this.state.searchedValue = this.state.value;
            await this.handleQuery();
        }
    }

    async handleQuery() {
        this.setState({ loading: true });
        const url = `api${this.state.caller.pathname}/${this.state.value}/${this.state.offset}`;
        this.state.response = await this.state.fetcher.FetchData(url);

        if (typeof this.state.response.message !== undefined || this.state.response.message !== null) {
            if (this.state.response.isError) {
                toast.error(this.state.response.message);
                this.setState({
                    customers: [],
                    isLastPage: false,
                    loading: false,
                    searched: true,
                    errored: true
                });
            } else {
                toast.success(this.state.response.message);
                this.setState({
                    customers: this.state.response.data.customers || [],
                    isLastPage: this.state.response.data.endOfList,
                    loading: false,
                    searched: true,
                    errored: false
                });
            }
        }
    }

    async handleNext() {
        this.state.offset += this.state.offsetBase;
        await this.handleQuery();
    }

    async handlePrevious() {
        if (this.state.offset > 0) {
            this.state.offset -= this.state.offsetBase;
            await this.handleQuery();
        }
    }

    async openModal() {
        this.setState({ modalIsOpen: true });
    }

    async closeModal() {
        this.setState({ modalIsOpen: false });
    }

    render() {
        var contents = null;
        if (this.state.customers.length >= 1 && this.state.loading === false) {
            contents = FilterTable.renderCustomerTable(this.state.customers);
        } else if (this.state.loading) {
            contents = <ReactLoading type='bars' name='bars' color='#222' />;
        } else if (this.state.errored) {
            contents = <div>
                <p className="warningMsg"> {this.state.response.message} </p>
            </div>;
        }
        else if (this.state.searched && this.state.offset > 0 && this.state.customers.length === 0 && this.state.isLastPage) {
            contents = <div>
                <p className="warningMsg"> There are no more results for the query.</p>
            </div>;
        }
        else if (this.state.searched && this.state.offset > 0 && this.state.customers.length === 0 && !this.state.isLastPage) {
            contents = <div>
                <p className="warningMsg"> TCM has returned only consumer records. Click Next to request more records.</p>
            </div>;
        }
        else if (this.state.searched) {
            contents = <div>
                <p className="warningMsg">No records found matching supplied search parameters</p>
            </div>;
        } else {
            contents = null;
        }

        return (
            <div>
                <Form inline
                    onKeyDown={
                        (e) => {
                            /**
                             * Note: Pressing enter in some input in a browser forms
                             *  triggers onClick on the first child button
                             *
                             * So, prevent `enter` from triggering `onClick` on any buttons
                             *  and instead trigger onSubmit
                             */
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                this.handleSubmit();
                            }
                        }
                    }

                    onSubmit={
                        (e) => {
                            /**
                             * Prevent submit from reloading the page
                             */
                            e.preventDefault();
                            e.stopPropagation();
                            this.handleSubmit();

                        }
                    }>
                    <FormGroup>
                        <FormControl
                            type="text"
                            placeholder="Enter text"
                            onChange={this.updateInput} value={this.state.value}
                        />
                        {' '}
                        <Button onClick={this.handleSubmit} disabled={!this.state.formValid || this.state.loading}> Submit </Button>
                    </FormGroup>
                </Form>
                <p><i>Please allow up to 1 minute per query</i></p>
                <FormErrors formErrors={this.state.formErrors} />
                {contents}
                {!this.state.loading && this.state.searched ?

                    <div>
                        {this.state.offset > 0 ?
                            <Button onClick={this.handlePrevious}> Previous </Button>
                            : null}

                        {' '}

                        {this.state.errored ?
                            <Button onClick={this.handleQuery}> Retry </Button>
                            : null
                        }

                        {!this.state.isLastPage && !this.state.errored ?
                            <Button onClick={this.handleNext}> Next </Button>
                            : null}

                        {' '}
                        
                        {/* Seperated into 2 ifs as if it is one it moves itself to a new line which we dont want*/}
                        {this.state.source == 'Name' && !this.state.errored ?
                            <Button onClick={this.openModal}> {"Request Creation of " + this.state.searchedValue} </Button>
                            : null
                        }
                        {this.state.source == 'Name' && !this.state.errored ?
                            <CIDNEntryForm modalOpen={this.state.modalIsOpen} closeModal={this.closeModal} name={this.state.searchedValue} />
                            : null}

                    </div>
                    : null}
                <ToastContainer />
            </div>
        );
    }
}
