import React, { Component } from 'react';
import { FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
import Modal from 'react-modal';
import { Col, Grid, Row } from 'react-bootstrap';
import { Validation } from './Validation';
import { FormErrors } from './FormErrors';
import { Fetcher } from './Fetcher';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
const { getNames } = require('country-list');


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "500px",
        formErrors: { abn: '' },
        maxHeight: '100vh'
    }
};

const dividerStyle = {
    width: '5px',
    height: 'auto',
    display: 'inline-block'
}

export class CIDNEntryForm extends Component {

    getValidationState(e) {
        var value = this.state[e]

        if (!value) {
            return null;
        }

        const length = value.length;

        if (length > 0) return 'success';
        return 'error';
    }

    getValidationEmail(e) {
        var value = this.state[e]

        if (!value) {
            return null;
        }

        if (this.validateEmail(value)) return 'success';
        return 'error';
    }

    getValidationPhone(e) {
        var value = this.state[e]

        if (!value) {
            return null;
        }

        if (this.validatePhone(value)) return 'success';
        return 'error';
    }

    getABNValid() {
        if (Validation.validateABN(this.state.abn).valid) {
            return 'success';
        }
        else {
            return 'error';
        }
    }

    handleChange(e) {
        let change = {}
        change[e.target.name] = e.target.value
        this.setState(change);
    }

    createMenuItems() {
        let items = []
        let countries = getNames();
        debugger;
        for (let i = 0; i <= countries.length; i++) {
            items.push(<option key={i} value={countries[i]}>{countries[i]}</option>);
        }
        return items;
    }

    constructor(props) {
        super(props);
        this.sendMail = this.sendMail.bind(this);
        this.state = {
            fetcher: new Fetcher(),
            abn: props.abn || '',
            name: props.name || '',
            email: '',
            streetNumber: '',
            streetName: '',
            city: '',
            state: '',
            postCode: '',
            primaryContactName: '',
            primaryContactEmail: '',
            primaryContactMobile: '',
            formErrors: { text: '' },
            loading: false,
            country: 'Australia',
            countries: this.createMenuItems()
        }

    }

    validate() {
        var currentFormErrors = { mandatory: '', abn: '', email: '', primaryContactEmail: '', primaryContactMobile: '' };

        if (!this.state.name ||
            !this.state.streetNumber ||
            !this.state.streetName ||
            !this.state.city ||
            !this.state.state ||
            !this.state.postCode ||
            !this.state.primaryContactName ||
            !this.state.primaryContactEmail ||
            !this.state.primaryContactMobile
        ) {
            currentFormErrors.mandatory = "All fields are mandatory.\n";
        }
        var abn = Validation.validateABN(this.state.abn);
        if (!abn.valid) {
            currentFormErrors.abn = "ABN has failed validation with the message: ";
            if (this.state.abn === "") {
                currentFormErrors.abn += "ABN is Required.";
            }
            else {
                currentFormErrors.abn += abn.search;
            }
        }

        if (!this.state.email || !this.validateEmail(this.state.email)) {
            currentFormErrors.email = "Email needs to be in the correct format.";
        }
        if (!this.state.email || !this.validateEmail(this.state.primaryContactEmail)) {
            currentFormErrors.primaryContactEmail = "Contact Email needs to be in the correct format.";
        }
        if (!this.state.primaryContactMobile || !this.validatePhone(this.state.primaryContactMobile)) {
            currentFormErrors.primaryContactMobile = "Contact Phone Number needs to be 10 digits long";
        }

        this.setState({
            formErrors: currentFormErrors
        });
        return currentFormErrors.primaryContactMobile === '' && currentFormErrors.primaryContactEmail === '' &&
            currentFormErrors.email === '' && abn.valid && currentFormErrors.mandatory === '';
    }


    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validatePhone(inputtxt) {
        var phoneno = /^\d{10}$/;
        return inputtxt.match(phoneno);
    }

    async sendMail() {
        if (!this.validate()) {
            return;
        }
        var data = JSON.parse(JSON.stringify(this.state));
        delete data.formErrors;
        delete data.fetcher;
        delete data.loading;

        this.setState({ loading: true });

        var resp = await this.state.fetcher.PostData('email', data);

        this.setState({ loading: false });

        if (typeof resp.data !== undefined || resp.data !== null) {
            if (resp.isError) {
                toast.error(resp.data.message);
                if (resp.data.hasOwnProperty("error")) {
                    console.log(resp.data.error);
                }
            } else {
                toast.success(resp.data.message);
            }
        }
        this.props.closeModal();
    }

    render() {
        return <div>
            <Modal
                isOpen={this.props.modalOpen}
                contentLabel="Application Entry"
                style={customStyles}
            >
                <div>
                    <form
                        onKeyDown={
                            (e) => {
                                /**
                                 * Note: Pressing enter in some input in a browser forms
                                 *  triggers onClick on the first child button
                                 *
                                 * So, prevent `enter` from triggering `onClick` on any buttons
                                 *  and instead trigger onSubmit
                                 */
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    this.handleSubmit();
                                }
                            }
                        }

                        onSubmit={
                            (e) => {
                                /**
                                 * Prevent submit from reloading the page
                                 */
                                e.preventDefault();
                                e.stopPropagation();
                                this.handleSubmit();

                            }
                        }>
                        <FormGroup
                            controlId="name"
                            validationState={this.getValidationState("name")}
                        >
                            <ControlLabel>Company Trading Name</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.name}
                                onChange={this.handleChange.bind(this)}
                                name="name"
                            />
                        </FormGroup>
                        <FormGroup
                            controlId="abn"
                            validationState={this.getABNValid()}
                        >
                            <ControlLabel>ABN</ControlLabel>
                            <FormControl
                                type="number"
                                value={this.state.abn}
                                onChange={this.handleChange.bind(this)}
                                name="abn"
                            />
                        </FormGroup>
                        <FormGroup
                            controlId="email"
                            validationState={this.getValidationEmail("email")}
                        >
                            <ControlLabel>Email</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.email}
                                onChange={this.handleChange.bind(this)}
                                name="email"
                            />
                        </FormGroup>
                        <Grid fluid>
                            <Row>
                                <Col xs={4}>

                                    <FormGroup
                                        controlId="streetNumber"
                                        validationState={this.getValidationState("streetNumber")}
                                    >
                                        <ControlLabel>Street Number</ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.streetNumber}
                                            onChange={this.handleChange.bind(this)}
                                            name="streetNumber"
                                        />
                                    </FormGroup>
                                </Col >
                                <Col xs={8}>
                                    <FormGroup
                                        controlId="streetName"
                                        validationState={this.getValidationState("streetName")}
                                    >
                                        <ControlLabel>Street Name</ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.streetName}
                                            onChange={this.handleChange.bind(this)}
                                            name="streetName"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <FormGroup
                                        controlId="city"
                                        validationState={this.getValidationState("city")}
                                    >
                                        <ControlLabel>City</ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.city}
                                            onChange={this.handleChange.bind(this)}
                                            name="city"
                                        />
                                    </FormGroup>
                                </Col >
                                <Col xs={3}>
                                    <FormGroup
                                        controlId="state"
                                        validationState={this.getValidationState("state")}
                                    >
                                        <ControlLabel>State</ControlLabel>
                                        <FormControl
                                            type="text"
                                            value={this.state.state}
                                            onChange={this.handleChange.bind(this)}
                                            name="state"
                                        />
                                    </FormGroup>
                                </Col>

                                <Col xs={3}>
                                    <FormGroup
                                        controlId="postcode"
                                        validationState={this.getValidationState("postCode")}
                                    >
                                        <ControlLabel>Postcode</ControlLabel>
                                        <FormControl
                                            type="number"
                                            value={this.state.postCode}
                                            onChange={this.handleChange.bind(this)}
                                            name="postCode"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <FormGroup>
                                        <ControlLabel>Country</ControlLabel>
                                        <FormControl componentClass="select" placeholder="Country" value={this.state.country} onChange={this.handleChange.bind(this)} name="country">
                                            {this.state.countries}
                                        </FormControl>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Grid>
                        <FormGroup
                            controlId="contactName"
                            validationState={this.getValidationState("primaryContactName")}
                        >
                            <ControlLabel>Contact Name</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.primaryContactName}
                                onChange={this.handleChange.bind(this)}
                                name="primaryContactName"
                            />
                        </FormGroup>
                        <FormGroup
                            controlId="contactEmail"
                            validationState={this.getValidationEmail("primaryContactEmail")}
                        >
                            <ControlLabel>Contact Email</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.primaryContactEmail}
                                onChange={this.handleChange.bind(this)}
                                name="primaryContactEmail"
                            />
                        </FormGroup>
                        <FormGroup
                            controlId="contactMobile"
                            validationState={this.getValidationPhone("primaryContactMobile")}
                        >
                            <ControlLabel>Contact Mobile</ControlLabel>
                            <FormControl
                                type="number"
                                value={this.state.primaryContactMobile}
                                onChange={this.handleChange.bind(this)}
                                name="primaryContactMobile"
                            />
                        </FormGroup>
                    </form>

                    All Fields are mandatory
                    {' '}
                    <FormErrors formErrors={this.state.formErrors} />

                    <Button onClick={this.props.closeModal} disabled={this.state.loading}>Cancel</Button>
                    {' '}
                    <Button onClick={this.sendMail} disabled={this.state.loading}>Submit</Button>
                    {this.state.loading ? <ReactLoading type='bars' name='bars' color='#222' /> : null}
                </div>
            </Modal>
        </div>;
    }
}