import { Component } from 'react';


export class SearchTool extends Component {

    static getSearchField(props) {
        var value = '';
        var search = props.source.search;
        var pathname = props.source.pathname;
        var removedSlash = pathname.replace('/', '');

        // Only if there is a search term
        if (search !== "") {
            var urlParams = new URLSearchParams(search);
            if (urlParams.has(removedSlash)) {
                value = urlParams.get(removedSlash);
            }
        }
        return value;
    }
}